<template>
  <div class="down">
    <div class="down-head" id="pageTop">
      <ArrowLeftOutlined class="back" @click="back()" />
      <h3>{{ $t("downloadCenter") }}</h3>
    </div>
    <div class="down-body">
      <div class="down-body-content">
        <div class="search">
          <div class="search-icon" @click="search">
            <SearchOutlined style="color: #656565" />
          </div>
          <a-input
            class="search-input"
            v-model:value.trim="searchVal"
            :placeholder="$t('PleaseEnterFileName')"
            allow-clear
            @pressEnter="search"
          />
        </div>
        <a-spin :spinning="loading">
          <template v-if="downList.length">
            <a-row :gutter="16">
              <a-col :span="6" v-for="item in downList" :key="item.downId">
                <a-card hoverable class="down-item">
                  <div class="dot" v-if="item.downs <= 0"></div>
                  <a-card-meta>
                    <template #avatar>
                      <a-avatar :style="{ backgroundColor: icon[item.suffix] }">
                        {{ item.suffix }}
                      </a-avatar>
                    </template>
                    <template #title>
                      <a-tooltip placement="topLeft" :title="item.title">
                        {{ item.title }}
                      </a-tooltip>
                    </template>
                    <template #description>
                      <span>{{ dateFormat(item.startTime) }}</span>
                      <a-tooltip placement="topLeft" :title="item.source1">
                        <p class="source">
                          {{ $t("LB_Doc_Source") }}：{{ item.source1 || "---" }}
                        </p>
                      </a-tooltip>
                    </template>
                  </a-card-meta>
                  <template v-if="item.status === 1">
                    <div class="down-item-ctrl pointer" @click="down(item)">
                      {{ $t("cm_download") }}
                      <span>{{ $t("expiresAfter3Days") }}</span>
                    </div>
                  </template>
                  <div class="down-item-ctrl" v-else>
                    {{ $t("generating") }}...
                  </div>
                </a-card>
              </a-col>
            </a-row>
            <div class="page-Wrap">
              <a-pagination
                show-quick-jumper
                :defaultPageSize="20"
                v-model:current="currentPage"
                :show-total="(total) => $t('InTotal', { total: pageTotal })"
                :total="pageTotal"
                @change="pageChange"
              >
                <template #itemRender="{ type, originalElement }">
                  <a-button v-if="type === 'prev'">{{
                    $t("LastPage")
                  }}</a-button>
                  <a-button v-else-if="type === 'next'">{{
                    $t("NextPage")
                  }}</a-button>
                  <renderVNode v-else :vnode="originalElement"></renderVNode>
                </template>
              </a-pagination>
            </div>
          </template>
          <a-empty v-else style="padding-top: 100px" />
        </a-spin>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import { dateFormat, fileDownload } from "@/utils/tools.js";

import { allDown, downAdd } from "@/api/user";

const renderVNode = (_, { attrs: { vnode } }) => vnode;

export default defineComponent({
  components: {
    renderVNode,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const { t: $t } = useI18n();
    const state = reactive({
      loading: true,
      icon: {
        xlsx: "#32754a",
        docx: "#2e54a0",
        doc: "#2e54a0",
        pdf: "#cc2f2a",
        ppt: "#c6492b",
        pptx: "#c6492b",
        mp4: "#f7be69",
        zip: "#3ad330",
      },
      fieldList: [
        {
          key: "title",
          name: "文件名",
          i18nName: "page.fileName",
          type: 1,
          isshow: true,
        },
      ],
      downList: [],
      currentPage: 1,
      pageTotal: 1,
      searchVal: "",
    });

    const back = () => {
      router.go(-1);
    };

    const getDownList = (scroll = false) => {
      state.loading = true;
      allDown({
        page: state.currentPage,
        pageSize: 20,
        title: state.searchVal,
      }).then((res) => {
        state.loading = false;
        if (res.ret === 0) {
          state.pageTotal = res.data.totals;
          state.downList = res.data.list || [];
          if (scroll) {
            setTimeout(() => {
              document.getElementById("pageTop").scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 0);
          }
        }
      });
    };
    getDownList();

    const pageChange = (n) => {
      state.currentPage = n;
      getDownList(true);
    };

    const search = () => {
      state.currentPage = 1;
      getDownList();
    };

    const down = (item) => {
      proxy.$message.success($t("StartDownloading"));
      let fileName = item.title + "." + item.suffix;
      fileDownload(item.downUrl, fileName);
      downNumAdd(item);
    };

    const downNumAdd = (item) => {
      if (item.downs <= 0) {
        downAdd({ id: item.downId }).then((res) => {
          item.downs++;
        });
      }
    };

    return {
      dateFormat,
      ...toRefs(state),
      back,
      pageChange,
      search,
      down,
      downNumAdd,
    };
  },
});
</script>

<style scoped lang="less">
::v-deep(.ant-card) {
  .ant-card-body {
    padding: 16px;
  }
}
.down {
  min-height: 100vh;
  background-color: #f0f2f5;
  &-head {
    .mixinFlex(flex-start; center);
    height: 60px;
    padding: 0 16px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    .back {
      font-size: 16px;
      cursor: pointer;
      color: #555;
      padding: 2px;
    }
    h3 {
      margin: 0 0 0 14px;
      padding-left: 14px;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #333;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 1px;
        height: 20px;
        background-color: #d9d9d9;
      }
    }
  }
  &-body {
    width: 1200px;
    margin: 0 auto;
    &-content {
      padding: 16px 0 30px;
    }
  }
  &-item {
    margin-bottom: 16px;
    padding-bottom: 46px;
    position: relative;
    ::v-deep(.ant-card-body) {
      cursor: default;
    }
    &-ctrl {
      background-color: #fafafa;
      border-top: 1px solid #f0f0f0;
      zoom: 1;
      height: 46px;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      .mixinFlex(center; center);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      span {
        color: rgba(0, 0, 0, 0.4);
      }
      &.pointer {
        cursor: pointer;
        &:hover {
          color: var(--theme);
          transition: color 0.3s;
        }
      }
    }
    .source {
      .mixinEllipsis(24px);
      margin: 4px 0 0 0;
    }
  }
  ::v-deep(.select-item) {
    .itemClose {
      display: none !important;
    }
  }
  .dot {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #e8673e;
  }
  .page-Wrap {
    text-align: center;
    margin-top: 8px;
  }
}
.search {
  border: 1px solid #ccc;
  .mixinFlex(space-between; center);
  width: 220px;
  height: 32px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 16px;
  &-icon {
    width: 34px;
    height: 14px;
    .mixinFlex(center; center);
    cursor: pointer;
  }
  ::v-deep(.ant-input-affix-wrapper-focused) {
    border-color: none;
    box-shadow: none;
  }
  &-input {
    border: none;
    width: calc(100% - 34px);
    height: 100%;
    background-color: #fff;
    padding: 0 8px 0 0;
    font-size: 14px;
    ::v-deep(.ant-input) {
      background-color: #fff;
    }
  }
}
</style>
